import { IMobileMenuState } from '@interfaces/page/store';

export const sessionStorageMenuState: string = 'mobileMenuState';
export const sessionStorageMenuPage = 'menuPageIndex';

export const getMobileMenuStateSessionStorage = (): Partial<IMobileMenuState> | null => {
  const value = sessionStorage && sessionStorage.getItem(sessionStorageMenuState);
  return value ? JSON.parse(value) : null;
};

export const setMobileMenuStateSessionStorage = (value: Partial<IMobileMenuState>): void => {
  return sessionStorage && sessionStorage.setItem(sessionStorageMenuState, JSON.stringify(value));
};
